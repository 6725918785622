<template>
    <website-page class="cms-page-html" :class="{'preview': preview}" v-style="website.body.page.style">
        Page Content

        <div v-if="!preview" class="keywords">
            <b-badge variant="light" class="p-2 m-1" v-for="word in keywords" :key="'keyword-' + word">{{word}}</b-badge>
        </div>
    </website-page>
</template>

<script>
import {isFrench} from "../../vue-components/helpers/i18n.js";
import Network from "../../vue-components/helpers/Network.js";

    export default {
        name: `cms-page-html`,
        props: {
            currentPage: { type: Object },
            preview: { type: Boolean, default: false }
        },
        data() {
            return {
                isFrench: isFrench,
                pageData: null
            }
        },
        computed: {
            getTemplate() {
                let content = null;
                if (isFrench()) {
                    content = this.currentPage.content_fr;
                    if (!content) {
                        content = this.currentPage.content_en;
                    }
                } else {
                    content = this.currentPage.content_en;
                    if (!content) {
                        content = this.currentPage.content_fr;
                    }
                }

                return `<div>${content}</div>`;
            },
            url() {
                if (this.currentPage && this.currentPage.url) {
                    return `/${this.currentPage.website_id}/${this.currentPage.url}`;
                } else {
                    return `/${this.currentPage.website_id}/${this.currentPage.id}`;
                }
            },
            keywords() {
                if (this.currentPage && this.currentPage.tags) {
                    return JSON.parse(this.currentPage.tags);
                } else {
                    return [];
                }
            }
        },
        methods: {
            navigated() {
                this.$emit(`navigated`);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .dynamic-page-content {
        margin-left: auto;
        margin-right: auto;

        ::v-deep img {
            max-width: 100%;
        }
    }
</style>
